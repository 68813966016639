<template>
  <v-card
    v-if="$route.query.id"
    :key="mounted"
    :class="highlightForm && 'highlight-required-fields'"
  >
    <div class="mb-4 sticky-header">
      <PatientInformation
        v-if="encounter.practice_type_id && patient"
        title="Podiatry Encounter Detail"
        :patient="patient"
        :visitType="encounter.visit_type"
      ></PatientInformation>
    </div>

    <Attachments
      :is-signed="encounter.is_signed"
      :patient-id="patient.id"
      :encounter-attachments.sync="patient.all_attachments"
    ></Attachments>

    <v-card-actions class="align-start pb-0">
      <!-- validated -->
      <icon-value
        :icon="formValidated ? icons.mdiCheckDecagram : icons.mdiCloseCircle"
        :value="formValidated ? 'Validated' : 'Not Validated'"
        :color="formValidated ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- signed -->
      <icon-value
        :icon="encounter.is_signed ? icons.mdiClipboardCheck : icons.mdiCloseCircle"
        :value="encounter.is_signed ? 'Signed' : 'Not Signed'"
        :color="encounter.is_signed ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- synced -->
      <icon-value
        :icon="encounter.is_synced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle"
        :value="encounter.is_synced ? 'Synced' : 'Not Synced'"
        :color="encounter.is_synced ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- review surgical note -->
      <btn
        v-if="formValidated"
        label="Review Note"
        :icon="icons.mdiEyeOutline"
        @click="viewSurgicalNote"
      ></btn>

      <!-- validate note -->
      <btn
        v-else
        label="Validate"
        color="warning"
        :icon="icons.mdiCheckDecagram"
        @click="validateEncounter"
      ></btn>
    </v-card-actions>

    <!-- *** INSURANCE AND AUTHORIZATION -->
    <InsuranceAuthorization
      :patient-data="patient"
      :encounter="encounter"
      :comment-adds="true"
    >
    </InsuranceAuthorization>

    <NoteSection
      label="Encounter Comments"
      heading
    ></NoteSection>
    <Comments
      type="encounter"
      :encounter-id="encounter.id"
      :patient-id="patient.id"
    >
    </Comments>

    <v-form
      v-model="formValid"
      :class="encounter.is_signed ? 'form-disabled' : ''"
      :disabled="encounter.is_signed"
    >
      <EncounterDetails
        :visit-location.sync="encounter.visit_location"
        :visit-type="encounter.visit_type"
        :place-of-service-id.sync="encounter.place_of_service_id"
        :visit-date.sync="encounter.visit_date"
        :encounter="encounter"
      ></EncounterDetails>

      <NoteSection label="Encounter Notes">
        <text-area
          v-model="encounter.notes"
          label="Enter encounter notes..."
          counter="0"
          rows="5"
          clear-warning
          required
        ></text-area>
      </NoteSection>

      <v-card-actions class="sticky-bottom">
        <!-- cancel -->
        <btn
          v-if="!encounter.is_signed && encounterChanged"
          label="Cancel"
          color="secondary"
          :icon="icons.mdiCancel"
          @click="cancelEncounter"
        ></btn>

        <!-- close -->
        <btn
          v-else
          label="Close"
          color="secondary"
          :icon="icons.mdiClose"
          @click="exit()"
        ></btn>

        <!-- delete note -->
        <btn
          v-if="!encounter.is_signed"
          label="Delete"
          :icon="icons.mdiTrashCan"
          :disabled="encounter.is_signed"
          color="error"
          @click="deleteEncounter"
        ></btn>

        <v-spacer></v-spacer>

        <!-- save note -->
        <btn
          v-if="formValidated"
          label="Save Note"
          :icon="icons.mdiContentSave"
          color="success"
          :disabled="invalidVisitDate"
          @click="saveEncounter"
        ></btn>

        <!-- save draft -->
        <btn
          v-else
          label="Save Draft"
          :icon="icons.mdiContentSaveEdit"
          color="success"
          :disabled="invalidVisitDate"
          @click="saveDraft"
        ></btn>

        <!-- review surgical note -->
        <btn
          v-if="formValidated"
          label="Review Note"
          :icon="icons.mdiEyeOutline"
          @click="viewSurgicalNote"
        ></btn>

        <!-- validate note -->
        <btn
          v-else
          label="Validate"
          color="warning"
          :icon="icons.mdiCheckDecagram"
          :disabled="invalidVisitDate"
          @click="validateEncounter"
        ></btn>
      </v-card-actions>
    </v-form>
    <!-- dump object -->
    <div
      v-if="$authUser.user() && $authUser.user().is_superuser"
      class="btn-top-center"
    >
      <btn
        label="Dump"
        :icon="icons.mdiDownload"
        x-small
        color="secondary"
        @click="$store.commit('encounters/updateEncounter', { encounter, patient });
                $router.push({ name: 'dump-encounter', query: { id: encounter.id } })"
      ></btn>
    </div>
  </v-card>
</template>

<script>

// Mixins
import Encounter from '@/mixins/Encounter'
import EncounterDetail from '@/mixins/EncounterDetail'

// Insurance & Authorization
import InsuranceAuthorization from '@/components/notes/insurance/InsuranceAuthorization.vue'

import PatientInformation from '@/components/notes/encounter/PatientInformation.vue'
import EncounterDetails from '@/components/notes/encounter/EncounterDetails.vue'
import Comments from '@/components/features/Comments.vue'
import Podiatry from '@/mixins/practice-types/Podiatry'

export default {
  components: {
    PatientInformation, EncounterDetails, InsuranceAuthorization, Comments,
  },
  mixins: [Podiatry, Encounter, EncounterDetail],
  data() {
    return {
      isQuickEntry: false,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    deleteEncounter() {
      this.$root.confirm({
        title: 'Delete Encounter Note?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to delete this encounter note?',
        confirm: 'Delete Note',
        confirmIcon: this.icons.mdiTrashCan,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          if (this.encounter.can_be_deleted) {
            this.$store.commit('encounters/deleteEncounter', this.encounter)
          } else {
            this.encounter.deleted = true
            this.encounter.is_synced = false
            this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
          }
          this.$store.dispatch('notify', { value: 'Encounter note deleted successfully.' })
          this.encounterChanged = false
          this.exit()
        }
      })
    },
  },
}
</script>
